

  export default function parseAnswerAttached(response) {

    let { data, included = [] } = response;
    
    return data.map(applicant => {

      let biosketchId = applicant.relationships.field_biosketch?.data?.id;
      let personalStatementId = applicant.relationships.field_res_personal?.data?.id;
      let lorChairId = applicant.relationships.field_lor_chair?.data?.id;
      let userId = applicant.relationships.uid?.data?.id;
      
      let biosketch = included.filter(biosketch => biosketch.type == 'file--file').find(biosketch => biosketch.id === biosketchId) || {};
      let personalStatement = included.filter(ps => ps.type == 'file--file').find(ps => ps.id === personalStatementId) || {};
      let lorChair = included.filter(lor => lor.type == 'file--file').find(lor => lor.id === lorChairId) || {};

      let lors = included.filter(lor => lor.type == 'node--lor_request' && lor.relationships.uid?.data?.id === userId) || [];
      
      return {
        ...applicant,
        attributes : {
          ...applicant.attributes,
          field_biosketch : {
            ...applicant.relationships?.field_biosketch,
            ...biosketch
          },
          field_res_personal : {
            ...applicant.relationships?.field_res_personal,
            ...personalStatement
          },
          field_lor_chair : {
            ...applicant.relationships?.field_lor_chair,
            ...lorChair
          },
          field_letters_of_recommendation: lors
        }
      }
    })
  }