import React from "react";
import {graphql, StaticQuery} from 'gatsby';
import Utils from "gatsby-theme-carbon/src/components/Utils";
import Layout from "../components/Layout.js";
import PageHeader from "gatsby-theme-carbon/src/components/PageHeader";
import Main from "gatsby-theme-carbon/src/components/Main";


const Default = ({ pageContext, children, location }) => {
  const { frontmatter = {}, titleType } = pageContext;
  const { description, keywords } = frontmatter;
  let title = (location.pathname === "/edit-profile") 
  ? "HEED Retreat Profile" 
  : (location.pathname.includes("/faqs")) 
  ? "HEED Retreat FAQs"
  : (location.pathname.includes("/reqs")) 
  ? "HEED Retreat Requirements"
  : "HEED Retreat" ;

  return (
    <StaticQuery
      query={graphql`
          query {
              allNodeTask(sort: {fields: title, order: ASC}) {
                  edges {
                      node {
                          drupal_id
                          title
                          fields {
                              slug
                          }
                      }
                  }
              }
              allNodeOption {
                edges {
                  node {
                    drupal_id
                    title
                  }
                }
              }
          }
      `}
      render={(
          data
      ) => {
        const tasks = data.allNodeTask.edges[0]?.node ? data.allNodeTask.edges.map(({node}, i) => {
          return { title: node.title, path: node.fields.slug, id: node.drupal_id, index: i };
        }) : []
        const options = data.allNodeOption.edges[0]?.node ? data.allNodeOption.edges.map(({node}, i) => {
          return { title: node.title, id: node.drupal_id };
        }) : []
        return (
            <Layout
              homepage={false}
              pageTitle={title}
              pageDescription={description}
              pageKeywords={keywords}
              titleType={titleType}
              tasks={tasks}
              options={options}
              path={location.pathname}
            >
              <PageHeader title={title} label="label" />
              <Main padded>{children}</Main>
              <Utils />
            </Layout>
        );
      }}
    />
  );
};

export default Default;
