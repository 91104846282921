export default (answers_data, tasks_count, included) => {
  let status = {
    answers: answers_data || {
      'type': 'node--answers', 
      attributes: {}
    },
    completed_tasks: [],
    completed_count: 0,
    all_done: false,
    percentage: 0,
    submitted: false,
    included
  }

  // if(!answers_data){
  //   // console.log('finding source in local data', userData);
    

  //   let is_unsaved_entity = false
  //   if(userData){
  //     let answers_local = []
  //     for (const a_key of Object.keys(userData)) {
  //       if(userData[a_key] && userData[a_key].type === 'node--answers'){
  //           answers_local.push(userData[a_key]);
  //           // break;
  //       }
  //     }
  //     if(answers_local.length > 0){
  //       //find best source
  //       // let best_answers_source = answers_local.find(a => typeof a.id !== 'undefined')

  //       let best_answers_source;
  //       let local_fetched_answers_json = localStorage.getItem('heedr_fetched_answers')
  //       if(local_fetched_answers_json) {
  //         local_fetched_answers_json = JSON.parse(local_fetched_answers_json)
  //         best_answers_source = answers_local.find(a => a.id == local_fetched_answers_json.id)
  //       } else
  //           best_answers_source = answers_local.find(a => typeof a.id !== 'undefined')

  //       if(!best_answers_source || isNewSubmission){
  //         best_answers_source = answers_local.find(a => typeof a.local_id !== 'undefined')
  //         if(best_answers_source) is_unsaved_entity = true
  //       }
  //       if(best_answers_source){
  //         status.answers = best_answers_source
  //       }
  //     }
  //   }
  //   if(is_unsaved_entity && typeof window !== 'undefined'){
  //     let local_fetched_answers_json = localStorage.getItem('heedr_fetched_answers')
  //     if(local_fetched_answers_json){
  //       const local_fetched_answers = JSON.parse(local_fetched_answers_json)
  //       if(local_fetched_answers){
  //         status.answers = local_fetched_answers
  //       }
  //     } 
  //   }
    
  //   // setSource(the_source)
  // }
  if(status.answers && status.answers.relationships?.field_completed_tasks?.data){
      if(Array.isArray(status.answers.relationships.field_completed_tasks.data) && status.answers.relationships.field_completed_tasks.data.length > 0){
          let set = new Set(status.answers.relationships.field_completed_tasks.data.map((t) => t.id));
          status.completed_tasks = Array.from(set);
      }
  }
  if(status.answers && status.completed_tasks.length > 0){
    status.completed_count = status.completed_tasks.length;
    status.all_done = status.completed_count === tasks_count;
    status.percentage = Math.ceil(status.completed_count * 100 / tasks_count);
  }
  if(status.answers?.attributes?.field_submitted){
    status.submitted = true;
  }
  // console.error('answers were parsed', status);
  return status
}
