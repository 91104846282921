import React, { useContext } from "react";
import { Link } from "gatsby";
import {
  Header as ShellHeader,
  HeaderMenuButton,
  SkipToContent,
  HeaderGlobalBar,
  HeaderGlobalAction,
  SideNav,
  SideNavItems,
  SideNavMenu,
  SideNavMenuItem,
} from "carbon-components-react";
import { User20, Close20 } from "@carbon/icons-react";
import cx from "classnames";

import NavContext from "gatsby-theme-carbon/src/util/context/NavContext";

import {
  header,
  switcherButtonOpen,
  skipToContent,
  headerName,
  collapsed,
  headerButton,
  switcherButton,
} from "./Header.module.scss";

const Header = ({ children }) => {
  const { toggleNavState, switcherIsOpen, searchIsOpen, leftNavIsOpen } = useContext(
    NavContext
  );
  return (
    <ShellHeader aria-label="Header" className={header}>
      <SkipToContent href="#main-content" className={skipToContent} />
      <HeaderMenuButton
        isActive={leftNavIsOpen}
        className={cx("bx--header__action--menu", headerButton)}
        aria-label="Open menu"
        onClick={() => {
          toggleNavState("leftNavIsOpen");
          toggleNavState("switcherIsOpen", "close");
        }}
      />
        <SideNav id="sidenav" 
        className="bx--side-nav__navigation" 
        defaultExpanded={false} 
        aria-label="Side navigation" 
        expanded={leftNavIsOpen}>
              <SideNavItems expanded={leftNavIsOpen}>
                <SideNavMenu 
                expanded={leftNavIsOpen} 
                title="HEED Retreat">
                  <SideNavMenuItem href="/application">
                    Application
                  </SideNavMenuItem>
                  <SideNavMenuItem href="/about">
                    About Us
                  </SideNavMenuItem>
                  <SideNavMenuItem href="/faqs">
                    FAQs
                  </SideNavMenuItem>
                  <SideNavMenuItem href="/reqs">
                    Application Requirements
                  </SideNavMenuItem>
                </SideNavMenu>
              </SideNavItems>
            </SideNav>
      <Link
        className={cx(headerName, {
          [collapsed]: searchIsOpen,
        })}
        to="/"
      >
        {children}
      </Link>
      <nav  className="bx--header__nav" >
        <ul className="bx--header__menu-bar">
          <li>
            <a
              icon=""
              className="bx--header__menu-item"
              tabIndex="0"
              href="/about"
            >
              <span className="bx--text-truncate--end">About</span>
            </a>
          </li>
        </ul>
      </nav>
      <HeaderGlobalBar>
        <HeaderGlobalAction
          className={cx(headerButton, switcherButton, {
            [switcherButtonOpen]: switcherIsOpen,
          })}
          aria-label="Switch"
          id="switchericon"
          onClick={() => {
            toggleNavState("switcherIsOpen");
            toggleNavState("searchIsOpen", "close");
            toggleNavState("leftNavIsOpen", "close");
          }}
        >
          {switcherIsOpen ? <Close20 /> : <User20 id="switchericon"/>}
        </HeaderGlobalAction>
      </HeaderGlobalBar>
    </ShellHeader>
  );
};

const DefaultHeaderText = () => (
  <>
    HEED&nbsp;<span>Retreat Form</span>
  </>
);

Header.defaultProps = {
  children: <DefaultHeaderText />,
};

export default Header;
